<template>
  <div>
    <b-row
      class="mb-2"
      v-for="profile in fields.filter((el) => el.group_id == 2)"
    >
      <b-col cols="4">
        <div v-if="form.country == 'ต่างประเทศ'">
          <p
            class="text-title-input"
            v-if="
              profile.field_profile_type_id == 24 ||
              profile.field_profile_type_id == 12
            "
          >
            {{ profile.name }}
            <span v-if="profile.required" class="text-danger">*</span>
          </p>
        </div>

        <p class="text-title-input" v-else-if="form.country == 'ประเทศไทย'">
          {{ profile.name }}
          <span v-if="profile.required" class="text-danger">*</span>
        </p>

        <div v-else>
          <p v-if="profile.field_profile_type_id == 24">
            {{ profile.name }}
            <span v-if="profile.required" class="text-danger">*</span>
          </p>
        </div>
      </b-col>
      <b-col
        :set="
          (field = v.address[checkIsProfile(profile.field_profile_type_id)])
        "
        :id="'field-' + profile.profile_name"
      >
        <InputSelect
          v-if="profile.field_profile_type_id == 24"
          title=""
          :placeholder="profile.name"
          :name="profile.name"
          v-model="form[checkIsProfile(profile.field_profile_type_id)]"
          :options="
            profile.field_choices.map((x) => ({
              text: x.name,
              value: x.name,
            }))
          "
          :isValidate="field.$error"
          :v="field"
          className="mb-2"
          @onDataChange="(val) => handleChangeCountry(val)"
        >
          <template v-slot:option-first>
            <b-form-select-option :value="null" disabled
              >-- ประเทศ --</b-form-select-option
            >
          </template>
        </InputSelect>
        <div v-if="form.country === 'ประเทศไทย'">
          <InputText
          v-if="
                      profile.field_type_id == 1 &&
                      profile.field_profile_type_id !== 19 &&
                      profile.field_profile_type_id !== 18 &&
                      profile.field_profile_type_id !== 17 &&
                      profile.field_profile_type_id !== 16
                    "
            :placeholder="profile.name"
            textFloat=""
            v-model="form[checkIsProfile(profile.field_profile_type_id)]"
            className="mb-2"
            :isValidate="field.$error"
            :v="field"
          />
          <InputSelect
            v-else-if="checkIsProfile(profile.field_profile_type_id) === 'province'"
            title=""
            placeholder="จังหวัด"
            name="province"
            v-model="form.province"
            className="mb-2"
            :options="[
              { text: 'กรุณาเลือกจังหวัด', value: null, disabled: true },
              ...province.map((x) => ({
                text: x.name,
                value: x.name,
              })),
            ]"
            :isValidate="field.$error"
            :v="field"
            @onDataChange="(val) => handleChange(val, 'province')"
          >
            <template v-slot:option-first>
              <b-form-select-option :value="null" disabled
                >-- จังหวัด --</b-form-select-option
              >
            </template>
          </InputSelect>
          <InputSelect
          v-else-if="checkIsProfile(profile.field_profile_type_id) === 'district'"
            title=""
            placeholder="เขต"
            name="district"
            v-model="form.district"
            className="mb-2"
            :isValidate="field.$error"
            :v="field"
            :options="[
              { text: 'กรุณาเลือกเขต', value: null, disabled: true },
              ...district.map((x) => ({
                text: x.name,
                value: x.name,
              })),
            ]"
            :disabled="!form.province"
            @onDataChange="(val) => handleChange(val, 'district')"
            ><template v-slot:option-first>
              <b-form-select-option :value="null" disabled
                >-- เขต --</b-form-select-option
              >
            </template>
          </InputSelect>
          <InputSelect
          v-else-if="
              checkIsProfile(profile.field_profile_type_id) === 'subdistrict'
            "
            title=""
            placeholder="แขวง"
            name="subdistrict"
            v-model="form.subdistrict"
            className="mb-2"
            :options="[
              { text: 'กรุณาเลือกแขวง', value: null, disabled: true },
              ...subdistrict.map((x) => ({
                text: x.name,
                value: x.name,
              })),
            ]"
            :isValidate="field.$error"
            :v="field"
            :disabled="!form.district"
            @onDataChange="(val) => handleChange(val, 'subdistrict')"
          >
            <template v-slot:option-first>
              <b-form-select-option :value="null" disabled
                >-- แขวง --</b-form-select-option
              >
            </template>
          </InputSelect>
          <InputText
          v-else-if="checkIsProfile(profile.field_profile_type_id) === 'zip_code'"
            ref="postNum"
            @onEnter="handleEnter(1)"
            textFloat=""
            placeholder="รหัสไปรษณีย์"
            name="zip_code"
            v-model="form.zip_code"
            className="mb-2"
            :disabled="true"
            @onKeypress="isNumber($event)"
          />
        </div>
        <div v-else-if="form.country === 'ต่างประเทศ'">
          <InputTextArea
            v-if="profile.field_profile_type_id == 12"
            :isValidate="field.$error"
            :v="field"
            textFloat=""
            name="text"
            rows="3"
            v-model="form[checkIsProfile(profile.field_profile_type_id)]"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import OtherLoading from "@/components/loading/OtherLoading";
import InputSelect from "@/components/inputs/InputSelect";
import InputText from "@/components/inputs/InputText";
import InputTextArea from "@/components/inputs/InputTextArea";
import { province } from "@/assets/json/fieldAddress.js";
import { fieldInForm2 } from "@/assets/json/fieldProfile.js";

export default {
  components: {
    InputTextArea,
    OtherLoading,
    InputSelect,
    InputText,
  },
  name: "NewCustomer",
  props: {
    form: {
      type: Object,
      default: true,
    },
    v: {
      type: Object,
      default: true,
    },
    fields: {
      type: Array,
    },
  },
  data() {
    return {
      isLoading: true,
      province,
      district: [],
      subdistrict: [],
    };
  },

  created: async function () {
    this.isLoading = false;
    this.getAddress();
  },
  methods: {
    async getAddress() {
      if (this.form.province) {
        let id = this.province.find((x) => x.name == this.form.province).id;
        const res = await this.$axios(
          `https://api-hourglass-mobile-dev.dosetech.co/api/v1/Service/Getsubaddress/${id}/${1}`
        );
        this.district = res.detail.district;
      }

      if (this.form.district) {
        let id = this.district.find((x) => x.name == this.form.district).id;
        const res = await this.$axios(
          `https://api-hourglass-mobile-dev.dosetech.co/api/v1/Service/Getsubaddress/${id}/${2}`
        );
        this.subdistrict = res.detail.subdistrict;
      }
    },
    checkFieldConditon(obj) {
      return obj.field_conditions[0] ? obj.field_conditions[0].value : "text";
    },
    checkIsProfile(val) {
      if (val == 99) val = 22;
      const findField = fieldInForm2.find((el) => el.id == val);
      return findField ? findField.key : false;
    },

    isNumber: function (e) {
      const maxlen = e.target.maxLength;
      const val = e.target.value;

      if (
        (e.key == "e" || e.charCode == 45 || e.charCode == 46) &&
        e.target.type == "number"
      ) {
        return e.preventDefault();
      }
      if (maxlen == -1) {
        return val;
      }

      if (val.length > maxlen - 1) {
        return e.preventDefault();
      }
    },
    checkFieldConditon(obj) {
      if (!obj.field_conditions) return "text";
      return obj.field_conditions[0] ? obj.field_conditions[0].value : "text";
    },

    handleChangeCountry(val) {
      this.form.country = val;

      if (val === "ประเทศไทย") return;
      else {
        this.form.zip_code = null;
        this.form.province = null;
        this.form.town = null;
        this.form.alley = null;
        this.form.road = null;
        this.form.subdistrict = null;
        this.form.district = null;
      }
    },
    async handleChange(val, field) {
      if (field == "province") {
        this.form.province = val;
        let id = this.province.find((x) => x.name == val).id;

        const res = await this.$axios(
          `https://api-hourglass-mobile-dev.dosetech.co/api/v1/Service/Getsubaddress/${id}/${1}`
        );
        this.district = res.detail.district;
        this.form.district = null;
        this.form.subdistrict = null;
        this.form.zip_code = null;
      } else if (field == "district") {
        this.form.district = val;
        let id = this.district.find((x) => x.name == val).id;
        const res = await this.$axios(
          `https://api-hourglass-mobile-dev.dosetech.co/api/v1/Service/Getsubaddress/${id}/${2}`
        );
        this.subdistrict = res.detail.subdistrict;
        this.form.subdistrict = null;
        this.form.zip_code = null;
      } else {
        this.form.subdistrict = val;
        let zip_code = this.subdistrict.find((x) => x.name == val).zip_code;
        this.form.zip_code = zip_code;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .custom-select:disabled {
  background-color: #d8dbe0 !important;
}

::v-deep input:disabled {
  background-color: #d8dbe0 !important;
}
</style>
